import React from "react";

import Home from "../../View/screens/public-screens/Home";
import ResetPassword from "../../View/screens/public-screens/auth/ForgetPassword";
import Login from "../../View/screens/public-screens/auth/Login";
import Register from "../../View/screens/public-screens/auth/Register";

import {
  About,
  Blog,
  BlogPost,
  Careers,
  Competitions,
  Contact,
  CreatePost,
  Deals,
  FAQ,
  Help,
  PrivacyPolicy,
  Services,
  SubmitFeedback,
  TermsAndConditions,
  WorkProcess,
} from "../../Controller/index";

import Reviews from "../../View/reviews/ReviewForm"

// ========== Private ==============
import {
  Account,
  Project,
  StartNewProject,
} from "../../Controller/index";
import Packages from "../../View/screens/public-screens/packages/Packages";
import Store from "../../View/screens/public-screens/Store"
import SubServicesList from "../../View/screens/public-screens/SubServicesList";
import AccountLinks from "../../View/screens/private/AccountLinks";
import SavedProjects from "../../View/screens/private/accountPages/SavedProjects";
import AddData from "../../Store"

// promotions
import LandingPage from "../../View/promotions/freedomday/LandingPage";
import Merch from "../../View/promotions/freedomday/Merch";
import PromoServices from "../../View/promotions/freedomday/PromoServices";
import PromoPayment from "../../View/promotions/freedomday/PromoPayment"
import OverViewPage from "../../View/promotions/freedomday/OverViewPage";
import VotingSystem from "../../View/VotingSystem/VotingSystem"


const home = {
  path: "/",
  component: <Home />,
};

const PublicRoutes = [
  // Promotions
  {
    path: "o/freedom-day-sale",
    component: <OverViewPage />,
  },
  {
    path: "/freedom-day-sale/payment",
    component: <PromoPayment />,
  },
  {
    path: "/freedom-day-sale/merchandise",
    component: <Merch />,
  },
  {
    path: "/freedom-day-sale",
    component: <LandingPage />,
  },
  {
    path: "/freedom-day-sale/services",
    component: <PromoServices />,
  },
  // In-house System Systems 
  {
    path: "/s/voting",
    component: <VotingSystem />,
  },
  {
    path: "/reviews/form",
    component: <Reviews />,
  },
  // Athentication
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
  },
  {
    path: "/packages",
    component: <Packages />,
  },
  {
    path: "/work-process",
    component: <WorkProcess />,
  },
  {
    path: "/services",
    component: <Services />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/about",
    component: <About />,
  },
  {
    path: "/careers",
    component: <Careers />,
  },
  {
    path: "/competitions",
    component: <Competitions />,
  },
  {
    path: "/deals",
    component: <Deals />,
  },
  {
    path: "/faq",
    component: <FAQ />,
  },
  {
    path: "/help",
    component: <Help />,
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/submit-feedback",
    component: <SubmitFeedback />,
  },
  {
    path: "/terms-and-conditions",
    component: <TermsAndConditions />,
  },
  {
    path: "/blog",
    component: <Blog />,
  },
  {
    path: "/blog-post",
    component: <BlogPost />,
  },
  {
    path: "/create-blog",
    component: <CreatePost />,
  },
  {
    path: "/addData",
    component: <AddData />,
  },
  {
    path: "/store",
    component: <Store />,
  },
  {
    path: "/services/:service",
    component: <SubServicesList />,
  },
];

const PrivateRoutes = [
  {
    path: "/account/:page",
    component: <AccountLinks />
  },
  {
    path: "/dashboard",
    component: <Account />,
  },
  {
    path: "/projects/:projectId",
    component: <Project />,
  },
  {
    path: "/start-new-project",
    component: <StartNewProject />,
  },
  {
    path: "/account/saved-projects",
    component: <SavedProjects />,
  },
  
];

export { home, PrivateRoutes, PublicRoutes };




// {
//   path: "account/invoices",
//   component: <Invoices />,
// },
// {
//   path: "account/personal-details",
//   component: <PersonalDetails />,
// },
// {
//   path: "account/projects",
//   component: <Projects />,
// },
// {
//   path: "account/projects/:projectId",
//   component: <Project />,
// },
// {
//   path: "account/purchases",
//   component: <Purchases />,
// },
// {
//   path: "account/communication",
//   component: <Communication />,
// },
// {
//   path: "account/contact",
//   component: <EmailsUS />,
// },
// {
//   path: "account/complaint",
//   component: <Complaints />,
// },
// {
//   path: "share-us",
//   component: <ShareUs />,
// },
// {
//   path: "account/feedback",
//   component: <GeneralFeedback />,
// },
// {
//   path: "account/submit-review",
//   component: <SubmitReview />,
// },
// {
//   path: "account/my-reviews",
//   component: <MyReviews />,
// },
// {
//   path: "account/start-project",
//   component: <StartNewProject />,
// },