import React, { useState, useEffect, useContext } from 'react';
import { db } from '../../../../Model/setup/firebase'
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useDocumentTitle } from "../../../../Controller/useDocumentTitle"
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../../../../AuthProvider'
import {
  FormButton,
} from "../../../styles/styled-components/Forms";

import { TextInput, TextArea, } from '../../../../Controller/index'

import {
  Container,
  Title
} from "../../../styles/styled-components/General";
import Loader from '../../../../Controller/Loader'

const EmailsUS = (props) => {
  const currentUser = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [toSend, setToSend] = useState({
    name: '',
    email: '',
    message: '',
    createdAt: serverTimestamp(),
    // time: Timestamp.fromDate(new Date().toLocaleTimeString()),
    status: '',
    userId: currentUser.uid,
  })
  async function submitForm(e) {
    e.preventDefault();

    setLoading(true)
    await addDoc(collection(db, "messages"),
    {
      name: props.details.fullName,
        email: props.details.email,
        message: toSend.message,
        createdAt: serverTimestamp(),
        // time: Timestamp.fromDate(new Date().toLocaleTimeString()),
        status: 'received',
        userId: currentUser.uid,
      }


    ).then((snap) => {
      console.log("message sent")
      toast("Message Sent Successfully");
      console.log(snap)
      setToSend({
        name: "",
        email: '',
        message: '',
        // createdAt: serverTimestamp(),
        // time: Timestamp.fromDate(new Date().toLocaleTimeString()),
        status: ''
      })
      setLoading(false)
    });

  }
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  }
  return (
    <>
      {
        loading ? (
          <Loader />
        ) : (


          <Container >
            <Title>Send Message</Title>
            <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={submitForm} >
              <TextArea label="Message" rows="4" cols="20" name="message" placeholder="Message ..." value={toSend.message} onchange={handleChange} errorMessage="This is the validation message" />
              <FormButton type="submit" value="SEND" />
            </form>
          </Container>
        )
      }
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default EmailsUS;
