import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../AuthProvider'
import { auth, db } from '../../../Model/setup/firebase'
import { collection, query, where, getDocs } from "firebase/firestore";
import { signOut } from "firebase/auth"
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router';
import { Navbar, Footer } from '../../../Controller/index';
import { useDocumentTitle } from '../../../Controller/useDocumentTitle'
import './styles/Account.style.css'
import { Title, SectionContainer, LinksContainer, SubTitle, LinkItem, LinkStyle, LinkListContainer } from './styles/Account.style'
import Loader from "../../../Controller/Loader"


const projects = [
  {
    name: "Project Name",
    type: "Graphic Design",
    startDate: "25 March 2022",
    endDate: "15 April 2022",
    status: "Saved"
  }
]

const Account = () => {
  useDocumentTitle("Client Dashboard");
  let navigate = useNavigate();
  const [userDetails, setClientDetails] = useState({})
  const [activeProjects, setActiveProjects] = useState([])
  const [loading, setLoading] = useState(true)
  const [clientName, setClientName] = useState("")
  const currentUser = useContext(AuthContext)
  console.log(currentUser)
  async function fetchData() {
    const q = query(collection(db, "clientAccount"), where("userId", "==", currentUser.uid));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      setClientName(doc.data().personalInfo.fullName)
      setLoading(false)
    });
  }
  useEffect(() => {
    fetchData();
  }, [currentUser]);
  function HandleSignOut() {
    signOut(auth).then(() => {
      navigate("/login")
    }).catch((error) => {
      console.error(error)
    });
  }
  return (
    <div>
      <Navbar />
      {
        loading ? (
          <Loader />
        ) : (

          <SectionContainer>
            <div className="header-container">
              <Title>Dashboard:  <span style={{ color: '#555', fontSize: '1.3rem', fontWeight: '500' }}>{clientName}</span> </Title>
              <Link className="start-project" to="/start-new-project">Start A New Project</Link>
            </div>
            <div className="active-projects-container">
              <h3>Active Projects</h3>
              <div className="active-projects-list">
                {
                  (activeProjects.length === 0) ? (
                    <div className="project-card no-projects" >
                      <h5>No Active Projects</h5>
                    </div>
                  ) : (
                    activeProjects.map((project, i) => {
                      return <Link key={i} to="/projects/graphi125" className="project-card" >
                        <div className="left-parts">
                          <h5 className="project-name">{project.name}</h5>
                          <p className="type">Type: <span>{project.type}</span></p>
                          <span className="start-date">Start Date: <span>{project.startDate}</span> </span>
                        </div>
                        <div className="right-parts">
                          <h6 className="status">{project.status}</h6>
                          <span className="end-date"><span className="estimate">Estimated </span> End Date: <span>{project.endDate}</span> </span>
                        </div>
                      </Link>
                    })
                  )
                }

              </div>
            </div>
            <div className="cards-container-top">
              <LinksContainer>
                <SubTitle>Projects</SubTitle>
                <LinkListContainer>
                  <Link style={LinkStyle} to="/account/projects">All Projects</Link>
                  <Link style={LinkStyle} to="/account/projects">Completed Projects</Link>
                  <Link style={LinkStyle} to="/account/saved-projects">Saved Projects</Link>
                </LinkListContainer>
              </LinksContainer>
              <LinksContainer>
                <SubTitle>Payments</SubTitle>
                <LinkListContainer>
                  <Link style={LinkStyle} to="/account/invoices">Invoices</Link>
                  <Link style={LinkStyle} to="/account/payment-history">Payment History</Link>
                  <Link style={LinkStyle} to="/account/vouchers">Reedem Gift Voucher</Link>
                </LinkListContainer>
              </LinksContainer>
              <LinksContainer>
                <SubTitle>Communication</SubTitle>
                <LinkListContainer>
                  <a style={LinkStyle} href="https://wa.me/27672679348" target="_blank" rel="noreferrer">Communicate on WhatsApp</a>
                  <Link style={LinkStyle} to="/account/contact">Send Us Email</Link>
                  <Link style={LinkStyle} to="/account/communication">Project Communication</Link>
                </LinkListContainer>
              </LinksContainer>
            </div>
            <div className="cards-container-bottom">
              <LinksContainer>
                <SubTitle>Client Details</SubTitle>
                <LinkListContainer>
                  <Link style={LinkStyle} to="/account/personal-details">Personal Details</Link>
                  {/* <Link style={LinkStyle} to="/account/address-info">Address</Link> */}
                  <Link style={LinkStyle} to="/account/newsletter">Newsletter</Link>
                </LinkListContainer>
              </LinksContainer>
              <LinksContainer>
                <SubTitle>Reviews and Feedback</SubTitle>
                <LinkListContainer>
                  <Link style={LinkStyle} to="/account/feedback">Submit A Feedback</Link>
                  <Link style={LinkStyle} to="/account/review">Review Our Service/Product</Link>
                  <Link style={LinkStyle} to="/account/share-us">Share us on Social Media</Link>
                </LinkListContainer>
              </LinksContainer>
              <LinksContainer>
                <SubTitle>Support</SubTitle>
                <LinkListContainer>
                  {/* <Link style={LinkStyle} to="/help">Suggest/Complain</Link> */}
                  <Link style={LinkStyle} to="/account/contact">Help</Link>
                  <div style={LinkStyle} onClick={HandleSignOut}>Log out</div>
                </LinkListContainer>
              </LinksContainer>
            </div>
          </SectionContainer>

        )
      }

      <Footer />
    </div>
  );
}

export default Account;
