// Provider.js
import { createContext, useReducer } from 'react';
import Reducer from './Reducer'

export const Context = createContext();

const initialState = {
    count: 0,
  };
  

export default function Provider(props) {
    const [state, dispatch] = useReducer(Reducer, initialState);
  return (
  <Context.Provider value={[state, dispatch]}>
      {props.children}
  </Context.Provider>
  )
}
