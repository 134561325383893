import React, {useState, useEffect} from 'react'
import Navbar from './navbar/Navbar'
import './Freedom.style.css'
import CountDown from './CountDown'
import {Link} from 'react-router-dom'

const Services = () => {
  const data = [
      {
        name: "Logo%20Design",
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/promotion-resources%2Fservices%2FSeparated-Services-01.png?alt=media&token=cf0f2e80-b706-4eb0-879a-51fdbac3fcd5"
      },
      {
        name: "Poster%20Design",
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/promotion-resources%2Fservices%2FSeparated-Services-02.png?alt=media&token=8fd95f64-853d-4c9c-9ce8-be846b137242"
      },
      {
        name: "Mockup Design",
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/promotion-resources%2Fservices%2FSeparated-Services-03.png?alt=media&token=45f95882-d80b-4fa3-97f5-b2524af0b299"
      },
      {
        name: "One%20Page%20Website",
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/promotion-resources%2Fservices%2FSeparated-Services-06.png?alt=media&token=4e8a78a5-3e27-4146-9422-c09ef9a07407"
      },

  ]
  return (
    <>
    <CountDown />
    <Navbar />
    <section className="services-container">
    <div className="merch-list">
    {
      data.map((item, i) => {
            return (
              <div key={i} className="service-box">
                <img src={item.imageUrl} alt="" className="image-container" />
                <a target="_blank" href={`http://wa.me/27672679348?text=${item.name}`} rel="noreferrer" className="btn order">Place Order Via WhatsApp</a>
              </div>
            )
          })
        }
    </div>
    </section>
</>
  );
}

export default Services;
