import React, {useState, useEffect} from 'react'
import { EditorState,  convertToRaw} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

function CreateBlogPost() {
    const [editorState, setEditorState] = useState({
        editorState: EditorState.createEmpty()
    })

    return (
        <div>
            <h1>Create Blog Post</h1>
            <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(editorState) => setEditorState({editorState,})}
            />
            <textarea
                disabled
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            />
        </div>
    )
}

export default CreateBlogPost
