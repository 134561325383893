import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import { db } from '../../../Model/setup/firebase'
import { getDocs, collection } from "firebase/firestore";
import { useDocumentTitle } from '../../../Controller/useDocumentTitle'
import './useful-pages/styles/Services.css'
import Loader from '../../../Controller/Loader'



const SubServicesList = () => {
  const { service } = useParams();
  var docTitle = service.replace('-', " ")
  docTitle = docTitle.replace(/\b\w/g, l => l.toUpperCase())
  useDocumentTitle(`Services - ${docTitle}`)
  const [graphicList, setGraphicList] = useState([])
  const [webList, setWebList] = useState([])
  const [mediaAssistanceList, setMediaAssistanceList] = useState([])
  const [techAssistanceList, setTechAssistanceList] = useState([])
  async function fetchData() {
    const querySnapshot = await getDocs(collection(db, "services-name"));
    querySnapshot.forEach((docItem) => {
      // doc.data() is never undefined for query doc snapshots
      switch (docItem.id) {
        case "Graphic Design":
          setGraphicList(docItem.data().services)
          break
        case "Web Development And Programming Solutions":
          setWebList(docItem.data().services)
          break
        case "Media Solutions":
          setMediaAssistanceList(docItem.data().services)
          break
        case "Tech Virtual Assistance":
          setTechAssistanceList(docItem.data().services)
          break;

        default:
          break;
      }
      console.log(docItem.id, " => ", docItem.data());
    });
  }
  useEffect(() => {
    fetchData()
  }, [])

  if (graphicList.length !== 0) {
    switch (service) {
      case "graphic-design":
        return (
          <>
            <Navbar />
            <GraphicDesign serviceData={graphicList} />
            <Footer />
          </>
        )
      case "software-development":
        return (
          <>
            <Navbar />
            <SoftwareDevelopment serviceData={webList} />
            <Footer />
          </>
        )
      case "tech-virtual-assistance":
        return (
          <>
            <Navbar />
            <VirtualAssistance serviceData={techAssistanceList} />
            <Footer />
          </>
        )
      case "media-solutions":
        return (
          <>
            <Navbar />
            <MediaSolutions serviceData={mediaAssistanceList} />
            <Footer />
          </>
        )
      default:
        return (
          <>
            <Navbar />
            <h1 style={{ width: '100vw', height: '85vh', display: 'grid', placeItems: 'center' }} >404</h1>
            <Footer />
          </>
        )
    }
  }
  else {
    return (
      <>
        <Navbar />
        <Loader />
        <Footer />
      </>
    )
  }

}

export default SubServicesList

const GraphicDesign = (props) => {
  return (
    <div className='sub-services-container'>
      <h1>Graphic Design Services</h1>
      <div className="sub-services-list">

        {
          props.serviceData.map((item, i) => {
            return (
              <div key={i} className="sub-service-item">
                <div>
                  <h5>{item}</h5>
                </div>
                <a target="_blank" rel="noreferrer"  href="https://wa.me/27672679348?text=I'm%20inquiring%20about%20the%20apartment%20listing`">
                  <h6>Quick Enquiry</h6>
                </a>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
const VirtualAssistance = (props) => {
  return (
    <div className='sub-services-container'>
      <h1>Tech Virtual Assistance</h1>
      <div className="sub-services-list">
        {
          props.serviceData.map((item, i) => {
            return (
              <div key={i} className="sub-service-item">
                <div>
                  <h5>{item}</h5>
                </div>
                <a href="https://wa.me/27672679348">
                  <h6>Get A Quick Quote</h6>
                </a>
              </div>
            )
          })
        }
      </div>
    </div>

  );
}
const SoftwareDevelopment = (props) => {
  return (

    <div className='sub-services-container'>
      <h1>Web/Software Development</h1>

      <div className="sub-services-list">

        {
          props.serviceData.map((item, i) => {
            return (
              <div key={i} className="sub-service-item">
                <div>
                  <h5>{item}</h5>
                </div>
                <a href="https://wa.me/27672679348">
                  <h6>Get A Quick Quote</h6>
                </a>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
const MediaSolutions = (props) => {
  return (
    <div className='sub-services-container'>
      <h1>Media Solutions</h1>
      <div className="sub-services-list">

        {
          props.serviceData.map((item, i) => {
            return (
              <div key={i} className="sub-service-item">
                <div>
                  <h5>{item}</h5>
                </div>

                <a href={`https://wa.me/27672679348?text=Hey,%20I%20am%20interested%20in%20${item.replaceAll(' ', '%20')}`}>
                  <h6>Make A Quick Enquiry</h6>
                </a>
              </div>
            )
          })
        }
      </div>
    </div>

  );
}
