import React, { useState, useEffect} from 'react'
import {db} from '../../Model/setup/firebase'
import {FormButton} from "../styles/styled-components/Forms";
import { TextInput, TextArea, } from '../../Controller/index'
import { Container, Title } from "../styles/styled-components/General";
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useDocumentTitle } from "../../Controller/useDocumentTitle"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Controller/Loader'

import "./Review.css"

const ReviewForm = () => { 
    useDocumentTitle("Review Form");
  const [loading, setLoading] = useState(false)
  const [toSend, setToSend] = useState({
    name: '',
    email: '',
    service: '',
    message: '',
    createdAt: serverTimestamp(),
    // time: Timestamp.fromDate(new Date().toLocaleTimeString()),
  })
  async function submitForm(e) {
    e.preventDefault();
    
    setLoading(true)
    await addDoc(collection(db, "reviews"),
      {
        name: toSend.name,
        email: toSend.email,
        message: toSend.message,
        service: toSend.service,
        createdAt: serverTimestamp(),
        // time: Timestamp.fromDate(new Date().toLocaleTimeString()),
      }
    ).then((snap) => {
      console.log("message sent")
      toast("Review Sent Successfully, Thank You");
      console.log(snap)
      setToSend({
      name: '',
      email: '',
      service: '',
      message: '',
      // createdAt: serverTimestamp(),
      // time: Timestamp.fromDate(new Date().toLocaleTimeString()),
    })
    setLoading(false)
    });
    
  }

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return (
    <>
         <Navbar />
      {
        loading ? (
          <Loader />
        ) : (
          <Container >
            <Title>Leave Us A Review</Title>
            <div className="description">
                <p >We are always striving to give the best value to our clients through providing quality work. Please let us know your feedback on how you were serviced by us.</p>
                <p className="key">Some Key Points For A Review</p>
                <ul>
                    <li>Would you recommend to us to other clients?</li>
                    <li>Are you happy with the quality of our work?</li>
                    <li>Was the service delivered on time?</li>
                    <li>Did our service solved your problem in a way you needed to?</li>
                </ul>
            </div>
            <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={submitForm} >
              <TextInput name="name" value={toSend.name} label="Name" placeholder="Your Name" onchange={handleChange} errorMessage="This is the validation message" />
              <TextInput value={toSend.email} name="email" placeholder="Your Email" label="Email" onchange={handleChange} errorMessage="This is the validation message" />
              <TextInput value={toSend.service} name="service" placeholder="The service we have offered..." label="Service" onchange={handleChange} errorMessage="This is the validation message" />
              <TextArea label="Review Message" rows="4" cols="20" name="message" placeholder="Review ..." value={toSend.message} onchange={handleChange} errorMessage="This is the validation message" />
              <FormButton type="submit" value="SEND" />
              <p id="note">Note: Your review might be shared on our website.</p>
            </form>
          </Container>
        )
      }
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer />
    </>
  )
}

export default ReviewForm
