import React, {useEffect, useState} from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import {useDocumentTitle} from "../../../Controller/useDocumentTitle"
import {useNavigate} from "react-router-dom"
// Components
import Banner from './home/Banner'
import Solutions from './home/Solutions'
// import HotPackages from './home/HotPackages'
// import Testimonies from './home/Testimonies'
// import WorkProcess from './home/WorkProcess'
import ProofElements from "./home/ProofElements";
import Includes from "./home/Includes"
import WhatsApp from "../../../Controller/WhatsApp";
import FreedomBanner from './home/FreedomBanner'
import './home/Home.style.css'


const Home = () => {
  useDocumentTitle("Home Page");
  const [showPromo, setShowPromo] = useState(true) 
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
})

useEffect(() => {
  setInterval(() => getDatesDifference(), 1000);
  setInterval(() => getTimeDifference("April 27, 2022"), 1000);
}, [showPromo])  
function getDatesDifference() {
  var post = new Date() > new Date("Apr 27 2022 00:00:01 GMT+0200") && new Date() < new Date("Apr 27 2022 23:59:59 GMT+0200")
  setShowPromo(post)
}
function getTimeDifference(eventDate) {
    const tim = Date.parse(eventDate) - Date.parse(new Date());
    const days = Math.floor(tim / (1000 * 60 * 60 * 24));
    const hours = Math.floor((tim / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((tim / 1000 / 60) % 60);
    const seconds = Math.floor((tim / 1000) % 60);
    setTime({ days: days, hours: hours, minutes: minutes, seconds: seconds });
}
  return (
    <div className="home">
      <Navbar />
        <section className="home-container">
            <Banner />
          <Solutions />
          {/* <HotPackages />
          <WorkProcess /> */}
          <ProofElements />
          {/* <Testimonies /> */}
          <Includes />
        <WhatsApp />
        </section>
      <Footer />
    </div>
  );
}

export default Home;
