import React, { useState } from "react";
import { Link } from 'react-router-dom'
import {
  FormGroup,
  Label,
  Input,
  Message,

  FormButton,
} from "../../../styles/styled-components/Forms";
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Title
} from "../../../styles/styled-components/General";
import Navbar from '../../../components/navbar/Navbar'
import Footer from '../../../components/footer/Footer'

import { auth } from '../../../../Model/setup/firebase'
// import { AuthContext } from '../../../../AuthProvider'
import { signInWithEmailAndPassword } from "firebase/auth";
import { useDocumentTitle } from "../../../../Controller/useDocumentTitle"
import Loader from "../../../../Controller/Loader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Login() {
  useDocumentTitle("Login");
  // const currentUser = useContext(AuthContext)
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const [toSend, setToSend] = useState({
    email: '',
    password: ''
  })

  function submitForm(e) {
    e.preventDefault();
    setLoading(true)
    console.log("submit function")
    signInWithEmailAndPassword(auth, toSend.email, toSend.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log("signed-in")
        console.log(user.uid)
        setLoading(false)
        navigate('/dashboard')
      })
      .catch((error) => {
        setLoading(false)
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorMessage.includes("user-not")) {
          toast("User Not Registered")
        }
        else if (errorMessage.includes("wrong-password")) {
          toast("Password Is Incorrect")
        }

        console.log(errorCode, errorMessage)
      });
    setToSend({
      email: '',
      password: ''
    })
  }

  // console.log(isAuth)
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Navbar />
      {
        loading ?
          <Loader /> :

          <Container >
            <Title>Login</Title>
            <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={submitForm} >
              <FormGroup>
                <Label>Email</Label>
                <Input required placeholder="Email" value={toSend.email} name="email" onChange={handleChange} />
                {(false) && <Message>This is the validation message</Message>}
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input type="password" placeholder="Password" value={toSend.password} name="password" onChange={handleChange} />
                {(false) && <Message>This is the validation message</Message>}
              </FormGroup>
              <FormButton type="submit" value="LOGIN" />
            </form>
            <Link to='/register'>Create a new account</Link>
            <Link to='/reset-password'>Forgot password</Link>
            <ToastContainer
              position="bottom-center"
              autoClose={4000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Container>
      }
      <Footer />
    </>
  )
}

export default Login









