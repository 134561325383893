import React from 'react';

const Includes = () => {
    return (
        <div id="includes">
            {/* <div className="our-business-structure">
                <h1>Our Business Structure</h1>
                <img src="https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/resources%2FBusinessStructure.png?alt=media&token=97f91023-6b43-48b1-8607-9881f7341cf6" alt="business-structure" className="includes-image" />
            </div> */}
            <div className="our-business-structure">
                <h1>Our Online Store</h1>
                <img src="https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/resources%2FOnlineStorePoster.jpg?alt=media&token=0fcca260-af87-462c-b33b-1198198a130b" alt="business-structure" className="includes-image" />
            </div>
            <div className="our-business-structure">
                <h1>Our Merch</h1>
                <img src="https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/resources%2FMerch.jpg?alt=media&token=59b14048-c2c0-4685-8dec-83cfe3c98f17" alt="business-structure" className="includes-image" />
            </div>
        </div>
    );
}

export default Includes;
