import React, {useState, useEffect} from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { useDocumentTitle } from "../../../Controller/useDocumentTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake, faPenNib, faCode, faPhotoVideo } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom"
import {db} from '../../../Model/setup/firebase'

import { doc, getDoc } from "firebase/firestore";
import './useful-pages/styles/Services.css'
import Loader from '../../../Controller/Loader'


const Services = () => {
  useDocumentTitle("Services");
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    async function fetchData() {
      const docRef = doc(db, "services", "services");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setServices(docSnap.data().serviceList)
        setLoading(false)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    fetchData()
  }, [])
  return (
    <div>
      <Navbar />
      {
        loading ?
        <Loader /> :
      <section className="services-container">
        <h1>Our Services By Category</h1>
        <div className="services-list-container">
          <div className="category-list">
            {
              (services.length === 4) &&
              services.map((item, i) => {
                let ICON = null;
                if (item.slug === "graphic-design") {
                  ICON = faPenNib;
                }
                else if (item.slug === "software-development") {
                  ICON =  faCode
                }
                else if (item.slug === "tech-virtual-assistance") {
                  ICON = faHandshake
                }
                else if (item.slug === "media-solutions") {
                  ICON = faPhotoVideo
                }
                return (
                  <div key={i} className="category-card">
                    <div className="icon-box">
                      <FontAwesomeIcon className="icon" icon={ICON} />
                    </div>
                    <h4>{item.category}</h4>
                    <div className="card-info">
                      <p>{item.description}</p>
                      <Link className="btn-learn-more" to={`/services/${item.slug}`}>Learn More</Link>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
      }
      <Footer />
    </div>
  );
};

export default Services;
