import React, {useState, useEffect} from 'react';

const CountDown = () => {
    const [time, setTime] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    })
    useEffect(() => {
        setInterval(() => getTimeDifference("Apr 27 2022 23:59:59 GMT+0200"), 1000);
    }, [])  
    function leadingZero(num) {
      return (num < 10 && num > 0) ? "0" + num : num;
    }
    function getTimeDifference(eventDate) {
        const time = Date.parse(eventDate) - Date.parse(new Date());
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const seconds = Math.floor((time / 1000) % 60);
        setTime({ days: days, hours: hours, minutes: minutes, seconds: seconds });
    }
  return (
    <div id="sale-countdown">
      <p>{`${leadingZero(time.hours)} hours ${leadingZero(time.minutes)} minutes ${leadingZero(time.seconds)} seconds `}<span>left until sale ends</span></p>
    </div>
  );
}

export default CountDown;
