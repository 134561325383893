import React, { useState, useEffect } from 'react'
import { Navbar, Footer } from '../../../../Controller/index'
import { db } from '../../../../Model/setup/firebase'
import {Link} from 'react-router-dom'
import Loader from "../../../../Controller/Loader"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons"

const Projects = () => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            {
                loading ?
                    <Loader /> :
                    <section className="all-projects">
                        <div className="projects-title">
                            <h1> <Link to="/dashboard"><FontAwesomeIcon style={{marginRight: '10px', fontSize: '20px'}} icon={faArrowLeft}/></Link> All Projects</h1>
                        </div>
                        <div className="all-projects-list">
                        <Link to="/projects/graphi125" className="project-card" >
                        <div className="left-parts">
                            <h5 className="project-name">Project Name</h5>
                            <p className="type">Type: <span>{`Graphic Design`}</span></p>
                            <span className="start-date">Start Date: <span>{`20 March`}</span> </span>
                        </div>
                        <div className="right-parts">
                            <h6 className="status">Status</h6>
                            <span className="end-date">Estimated End Date: <span>{`20 October`}</span> </span>
                        </div>
                        </Link>
                        </div>
                    </section>
            }
        </>
    )
}

export default Projects
