import React, {  useState, useRef, useCallback } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'
import ImageViewer from 'react-simple-image-viewer';

function ProofElements() {
    const container = useRef(null);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const ourWork = [
        {
            imageUrls: ["https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2Fsmartfit%2Fsmartfit-logo.jpg?alt=media&token=c7a9d623-3750-4522-8121-16535b7c98f3",
                    "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2Fsmartfit%2Fsmartmockups_l0y916mp.jpg?alt=media&token=ee4d4d28-43a5-48a4-9fc0-904a361edff1"
        ],
            link: "",
            description: "Smartfit is a company that deals with car tires from selling to repairing and more related services. We created a logo that best represents the company as well as according to the clients' specifications.",
            name: "Smartfit Logo Design"
        },
        {
            imageUrls: [ "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2FrossyTherapy%2Frossytherapylogo.jpg?alt=media&token=438c268b-26f1-451a-96d7-2acd2b314cf7",
            "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2FrossyTherapy%2Frossytherapy_home.jpg?alt=media&token=38abf37f-cc52-4ded-8083-bc4d264800d0",
            "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2FrossyTherapy%2Fbook.png?alt=media&token=d4bb9954-9fe1-4399-a990-f0cb56e601c1"
        ],
        link: "",
        description: "Rossy Therapy website is a website for a therapy business it provides information about the business and has the functionality of allowing people to book appointments online, the website is still at the test stage.",
        name: "Rossy Therapy Website"
    },
        {
            imageUrls: ["https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2FposterDesigns%2Funique-hand_poster_01_1080x1080.jpg?alt=media&token=023b21d8-fc5d-4274-b505-3d081cae31cd",
                "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2FposterDesigns%2FWesternRooster.jpg?alt=media&token=2a00f2bd-d81d-4653-985b-ab25801c9289"
            ],
            link: "",
            description: "Some of our poster designs are crafted to make a good impression on someone who is viewing and they are clean, simple, and concise which makes them sell.",
            name: "Some Poster Designs"
        },
        {
            imageUrls: [ "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2FIntraAfricanTrade%2FIntraAfricanTrade.jpg?alt=media&token=9af48bfc-eff1-4e3a-b571-22bd729040e5",
            "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2FIntraAfricanTrade%2FIntra-homepage.jpg?alt=media&token=8fa4116b-23fb-4ddd-9446-bc33df6b3ead",
            "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2FIntraAfricanTrade%2FScreenshot%202022-03-26%20144229.png?alt=media&token=ada6dfa2-280b-41bd-87fc-4c37ef689959"
            ],
            link: "",
            description: "Intra African Trade & Investment State Agency focuses on Investments in different projects and initiatives and buying and supplying goods in and around Africa. We have created a website to make their business have a presence online.",
            name: "Intra African Trade"
        },
        {
            imageUrls: [ "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2FinfoGraphics%2FInfoGraphics_01.jpg?alt=media&token=47780efd-457d-4467-b527-51566749bed4",
            "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2FinfoGraphics%2FInfoGraphics_02.png?alt=media&token=4c2e6cb4-23c0-440e-b8cf-374793407d43"
            ],
            link: "",
            description: "Some of the infographics design examples. With infographics design, we ensure that the message or information is visually communicated simply and graphically represented.",
            name: "Info Graphics Design"
        },
        {
            imageUrls: ["https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourWork%2FsampleLogos%2FSampleLogos.gif?alt=media&token=c52af5ac-8b67-4a4d-9d5b-f2ca1deed247"],
            link: "",
            description: "These are some of the logo designs we have created, expect to get such quality results if you work with us to build your brand identity.",
            name: "Logos We Have Created"
        },
    ]
    const Clients = [
        {
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourClients%2Ficulwelisha.png?alt=media&token=8f89ad57-96b8-411d-b9f6-b452a04c70f8",
            link: "",
            name: "Iculwelisha Foundation"
        },
        {
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourClients%2FIntraAfricanTrade.png?alt=media&token=f95d8038-1abd-4c51-8fcb-6391c210295c",
            link: "",
            name: "Intra African Investment And Trade"
        },
        {
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourClients%2Flibertad-styles.png?alt=media&token=a0a46d10-9eb6-4fd3-84be-b4d0b7186f53",
            link: "",
            name: "Libertad Styles"
        },
        {
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourClients%2FucanToo.png?alt=media&token=3352fac8-dc79-4259-917a-3b0bbea27b96",
            link: "",
            name: "You Can Too"
        },
        {
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourClients%2Funiquehad.jpg?alt=media&token=43e914b8-2d37-4715-9c02-43b77f2eceaf",
            link: "",
            name: "Unique Hand"
        },
        {
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourClients%2Fmax-the-barbar.png?alt=media&token=a9d89e9a-2155-4745-b368-f8e918b0e948",
            link: "",
            name: "Max The Barbar"
        },
        {
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/ourClients%2Ficon_round.svg?alt=media&token=c2b404a6-348b-4d5c-bc57-85f23fd01ba2",
            link: "",
            name: "Rossy Therapy"
        },
    ]
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    return (
        <div id="proof-elements">
            <div className="clients-container">
                <div className="clients-title">
                    <h1>Our Clients</h1>
                </div>
                <ScrollContainer
                    className="clients-list-container"
                    onScroll={() => console.log('Scrolling')}
                    onStartScroll={() => console.log('Start Scrolling')}
                    onEndScroll={() => console.log('End Scrolling')}
                    ref={container}
                >
                    <div className="our-clients-list">
                        {Clients.map((client, i) => {
                            return (
                                <div key={i} className="client-card">
                                    <img className="client-logo" src={client.imageUrl} alt={client.name} />
                                    <h1 className="name">
                                        {client.name}
                                    </h1>
                                </div>
                            );
                        })}
                    </div>
                </ScrollContainer>
                {/* <button onMouseUp={() => setMouse(false)} onMouseDown={scrollMoveContinue} onClick={scrollMove}>MOve</button> */}
            </div>
            <div className="projects-container">
                <div className="projects-title">
                    <h1>Some Of Our Best Work</h1>
                </div>
                <ScrollContainer className="our-recent-work-list-container" >

                    <div className="our-recent-work-list">
                        {
                            ourWork.map((item, i) => {
                                return (
                                    <div key={i} onClick={() => openImageViewer(i)} className="work-item-card">
                                        <div className="work-item-image">
                                            <img src={item.imageUrls[0]} alt={item.name} />
                                        </div>
                                        <div style={{ display: isViewerOpen ? 'none' : 'block' }} className="work-item-overlay"></div>
                                        <div style={{ display: isViewerOpen ? 'none' : 'block' }} className="work-item-content">
                                            <h2 className="project-name">
                                                {item.name}
                                            </h2>
                                            <p className="project-description">
                                                {item.description}
                                            </p>
                                            <span className="project-category">{item.link}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {isViewerOpen && (
                            <ImageViewer
                                src={ourWork[currentImage].imageUrls}
                                currentIndex={0}
                                disableScroll={false}
                                closeOnClickOutside={true}
                                onClose={closeImageViewer}
                                backgroundStyle={{
                                    backgroundColor: "rgba(0,0,0,0.9)"
                                }}
                            />
                        )}
                    </div>
                </ScrollContainer>
            </div>
        </div>
    );
}

export default ProofElements;
