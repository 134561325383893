import styled from "styled-components";

export const Container = styled.div `
    padding: 1.5rem 10vw;
    @media only screen and (max-width: 796px) {
        padding: 0.8rem 5vw;
    }
`;

export const Description = styled.div `
    margin: 1.1rem 0 0;
    width: 60vw;
    font-size: 0.8rem;
    @media only screen and (max-width: 796px) {
        width: 90vw;
    }
`;