import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";



const Banner = () => {
  return (
    <div id='banner'>
      {/* <img className="bg-img" src={BG} alt="hello" /> */}
      <div className="heading">
        <h1>Providing  <span>Digital Services</span> That <span>Counts!</span></h1>
        <a className="icon-container" href="#solutions">
          <FontAwesomeIcon className="icon-animation" icon={faArrowDown} />
        </a>
      </div>
    </div>
  )
}

export default Banner