import React from 'react'

function FAQ() {
    return (
        <div>
            <h1>FAQ</h1>
        </div>
    )
}

export default FAQ
