import React from 'react'
import styled from "styled-components";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25rem;
`;

const ErrorText = styled.div`
  margin-top: 5px;
  color: #e74c3c;
  font-size: 15px;
  padding: 0px 4px;
  min-height: 24px;
`;

const Input = styled.textarea`
	padding: 0.5em;
	color: #111;
	background: #fff;
	border: 1px solid #001D3D;
	border-radius: 3px;
	width: 100%;
	margin-bottom: 0.5em;
`;
const Label = styled.label`
	margin-bottom: 0.2rem;
	color: #111;
    display: block;
    font-size: 0.8rem;
`;
const Option = styled.option`
    padding: 0.2rem;
    border-radius: 1px;

`;
const SelectBox = styled.select`
	margin-bottom: 0.2rem;
	color: #111;
    padding: 0.5em;
    font-size: 0.8rem;
    border-radius: 3px;
    width: 100%;
`;

const optionStyle = {
    padding: '1.5em',
}

export const Select = (props) => {
    return (
        <InputWrapper>
            <Label>{props.label}</Label>
            <SelectBox>
                <Option style={optionStyle} value="">none</Option>
                <Option value="">none 2</Option>
                <Option value="">none 3</Option>
            </SelectBox>
        </InputWrapper>
    )
}
