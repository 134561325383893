import React, {useState, useEffect} from 'react'
import Navbar from './navbar/Navbar'
import './Freedom.style.css'
import CountDown from './CountDown'

const Merch = () => {
  const Merch = [
    {
      name: "Tshirt%20Order",
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/promotion-resources%2Fmerch%2FArtboard%201.png?alt=media&token=b20b626e-cc9a-4361-9952-135668693bd6"
    },
    {
      name: "Hoodie",
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/promotion-resources%2Fmerch%2FArtboard%202.png?alt=media&token=9fd6e029-69fc-4968-a9f4-d6378427e0ce"
    },
    {
      name: "Long%20Sleeve",
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/promotion-resources%2Fmerch%2FArtboard%203.png?alt=media&token=3498734e-8e84-4d0d-8d43-f11fdafc4a6a"
    },
  ]
  return (
  <>
    <CountDown />
    <Navbar />
    <section className="services-container">
      <div className="merch-list">
        {
          Merch.map((item, i) => {
            return (
              <div key={i} className="service-box">
                <img src={item.imageUrl} alt="" className="image-container" />
                <a target="_blank" href={`http://wa.me/27672679348?text=${item.name}`} rel="noreferrer" className="btn order">Place Order Via WhatsApp</a>
              </div>
            )
          })
        }
      </div>
      
    </section>
  </>
  );
}


export default Merch