import React, { useState } from "react";
import { useContext } from "react";
import { Context } from "./Provider";
import { db } from "./Model/setup/firebase"
import { doc, setDoc } from "firebase/firestore";
import { Services, graphicServices, webServices, mediaServices, assistanceServices } from "./View/screens/private/accountPages/newProject/Data"


function Store() {
  const [cartCount, setCartCount] = useState(0);
  const [state, dispatch] = useContext(Context);
  function increment() {
    dispatch({
      type: "INCREMENT",
      payload: 1,
    });
  }

  async function pushData() {
    var list = [graphicServices, webServices, mediaServices, assistanceServices]
    for (let i = 0; i < Services.length; i++) {
      await setDoc(doc(db, "services-name", Services[i]), {
        services: list[i],
      });
    }
    console.log("Done")
  }
  return (
    <div>
      <h4>Store</h4>
      <p>{state.count}</p>
      <button onClick={increment}>+</button>&nbsp;
      <button onClick={pushData}>Push Data</button>

    </div>
  );
}

export default Store;
