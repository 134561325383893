import React, { useState } from "react";
import {
  FormButton,
} from "../../../styles/styled-components/Forms";

import { TextInput, TextArea, } from '../../../../Controller/index'

import {
  Container,
  Title
} from "../../../styles/styled-components/General";
import Navbar from '../../../components/navbar/Navbar'
import Footer from '../../../components/footer/Footer'

import { db } from '../../../../Model/setup/firebase'
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useDocumentTitle } from "../../../../Controller/useDocumentTitle"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../Controller/Loader'

function Contact() {
  useDocumentTitle("Contact Us");
  const [loading, setLoading] = useState(false)
  const [toSend, setToSend] = useState({
    name: '',
    email: '',
    message: '',
    createdAt: serverTimestamp(),
    // time: Timestamp.fromDate(new Date().toLocaleTimeString()),
    status: ''
  })


  async function submitForm(e) {
    e.preventDefault();
    
    setLoading(true)
    await addDoc(collection(db, "messages"),
      {
        name: toSend.name,
        email: toSend.email,
        message: toSend.message,
        createdAt: serverTimestamp(),
        // time: Timestamp.fromDate(new Date().toLocaleTimeString()),
        status: 'received'
      }
    ).then((snap) => {
      console.log("message sent")
      toast("Message Sent Successfully");
      console.log(snap)
      setToSend({
      name: '',
      email: '',
      message: '',
      // createdAt: serverTimestamp(),
      // time: Timestamp.fromDate(new Date().toLocaleTimeString()),
      status: ''
    })
    setLoading(false)
    });
    
  }

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };


  return (
    <>
      <Navbar />
      {
        loading ? (
          <Loader />
        ) : (
          <Container >
            <Title>Contact Us</Title>
            <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={submitForm} >
              <TextInput name="name" value={toSend.name} label="Full Name" placeholder="Full Name" onchange={handleChange} errorMessage="This is the validation message" />
              <TextInput value={toSend.email} name="email" placeholder="Email" label="Email" onchange={handleChange} errorMessage="This is the validation message" />
              <TextArea label="Message" rows="4" cols="20" name="message" placeholder="Message ..." value={toSend.message} onchange={handleChange} errorMessage="This is the validation message" />
              <FormButton type="submit" value="SEND" />
            </form>
          </Container>
        )
      }
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer />
    </>
  );
}
export default Contact;
