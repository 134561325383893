import React,  {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import {AuthContext} from '../../../AuthProvider'

// pages 
import Contact from './accountPages/EmailsUS'
import Invoices from './accountPages/Invoices'
import Newsletter from './accountPages/Newsletter'
import PersonalDetails from './accountPages/PersonalDetails'
import Purchases from './accountPages/Purchases'
import Reviews from './accountPages/Reviews'
import Projects from './accountPages/Projects'
import Project from './accountPages/Project'
import GeneralFeedback from './accountPages/GeneralFeedback'
import SubmitReview from './accountPages/SubmitReview'
import Communication from './accountPages/Communication'
import Complaints from './accountPages/Complaints'
import StartNewProject from './accountPages/StartNewProject'
import Orders from './accountPages/SavedProjects'
import Vouchers from './accountPages/Vouchers'
import { auth, db } from '../../../Model/setup/firebase'
import { collection, query, where, getDocs } from "firebase/firestore";

const AccountLinks = () => {
    const {page} = useParams()
    const [details, setDetails] = useState({})
    const currentUser = useContext(AuthContext)
    async function fetchData() {
        const q = query(collection(db, "clientAccount"), where("userId", "==", currentUser.uid));
    
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          setDetails(doc.data().personalInfo)
        });
      }
    useEffect(() => {
        fetchData();
    }, [currentUser])
    switch(page) {
        case "projects":
            return (
                <>  
                    <Navbar />
                        <Projects />
                    <Footer />
                </>
              );
        case "project":
            return (
                <>  
                    <Navbar />
                        <Project />
                    <Footer />
                </>
              );
        case "invoices":
            return (
                <>  
                    <Navbar />
                        <Invoices />
                    <Footer />
                </>
              );
        case "orders":
            return (
                <>  
                    <Navbar />
                        <Orders />
                    <Footer />
                </>
              )
        case "wishlist":
            return (
                <>  
                    <Navbar />
                        
                    <Footer />
                </>
              )
        case "vouchers":
            return (
                <>  
                    <Navbar />
                        <Vouchers />
                    <Footer />
                </>
              )
        case "personal-details":
            return (
                <>  
                    <Navbar />
                        <PersonalDetails />
                    <Footer />
                </>
              )
        case "newsletter":
            return (
                <>  
                    <Navbar />
                        <Newsletter />
                    <Footer />
                </>
              )
        case "contact":
            return (
                <>  
                    <Navbar />
                        <Contact details={details} />
                    <Footer />
                </>
              )
        default:
            return (
                <>  
                    <Navbar />
                    <div>404</div>
                    <Footer />
                </>
              )
      }
}

export default AccountLinks