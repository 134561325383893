import React from "react";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/Footer";
import { SectionContainer} from "./styles/Styles";
import { useDocumentTitle } from "../../../../Controller/useDocumentTitle"

import './styles/About.css'

const teamProfiles = [
  {
    profileImage: "https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/aboutAssets%2FProfilePhoto.png?alt=media&token=a593e6fe-54d6-4ea5-bac3-745955d28a970",
    fullName: "Nkululeko Mbhele",
    role: "Founder And CEO",
    description: "I have a strong passion and profound interest in entrepreneurship, design and software development.The passion and interest has led me into starting this Software development and Design company.",
    linkedIn: "https://www.linkedin.com/in/nkululeko-mbhele/"
  },
]

function About() {
  useDocumentTitle("About Us");
  return (
    <>
      <Navbar />
      <SectionContainer>
        <section id="about">
          <h1 className="story-title">Our Story</h1>
          <div className="content-container">
            <div className="what-we-do">
              <h3>What We Do?</h3>
              <p><span>Nkululeko Dot IO (Pty) Ltd</span> is a Software Development and Design company that has a vision of creating fast and efficient softwares with automated systems, to increase high production rates and increased productivity, and win that freedom of doing other important task.</p>
            </div>
            <div className="who-we-are">
              <h3>What does <span>nkululeko.io</span>  mean?</h3>
              <img src="https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/aboutAssets%2Fbrandmeaning.png?alt=media&token=d25af664-a048-4a86-8dd9-9c1f66a06d2e" alt="brandMeaning" />
            </div>
            <br />
            <div className="vision">
              <h3>Vision</h3>
              <p>Our vision at Nkululeko Dot IO (Pty) Ltd is to be distinct from other design and software development agencies in creating Efficient, Scalable and Modern-Design and Custom software that suits the diverse needs of our end users at faster speed and to be the preferred design and software company for users in South Africa, and to consistently providing digital solutions that counts.</p>
            </div>
            <div className="mission">
              <h3>Mission</h3>
              <p>To achieve our vision, we intend to use latest technologies and modern design trends; and to have best team of software developers, graphic designers and content creators that will ensure that we attain our goal of being the preferred software company and in meeting all the diverse software needs of our customers here in South Africa.</p>
            </div>
            <div className="values">
              <h3>Our Core Values</h3>
              <ul>
                <li>Focus on creativity and modern style designs.</li>
                <li>Meet the changing needs and designs of clients..</li>
                <li>Accountability</li>
                <li>Value-centricity</li>
                <li>Quality</li>
                <li>Diversity and inclusion</li>
                <li>Passion</li>
                <li>Integrity</li>

              </ul>
            </div>
            <br />
            <div className="values">
              <h3>Our Products and Services</h3>
              <ul>
                <li>Graphic Design</li>
                <li>Sale of Design Templates</li>
                <li>Developing of software according to clients’ specifications</li>
                <li>Sale of software</li>
                <li>Offering of subscriptions for our software</li>
                <li>Sale of code scripts</li>
                <li>Advertisements on our blog page.</li>
                <li>Technical support</li>
              </ul>
            </div>
            <div className="team-container">
              <div className="title">
                <h1>Who is behind <span> NKULULEKO DOT IO (Ptd) Ltd</span>?</h1>
              </div>
              <div className="team">
                {/* <p>The company is still operating with one member Nkululeko Mbhele who works as a Graphic Designer and a Software Engineer, but we are still in search of more ambitious developers, designers to make up a fully strong team.If you are willing to work alongside Nkululeko you can just <Link to="/contact"> send us a message. </Link></p> */}
                <div className="profiles">
                  {
                    teamProfiles.map((profile, i) => {
                      return (
                        <div key={i} className="profile-card">
                          <div className="tag">
                            <img src={profile.profileImage} alt="profile" />
                            <div className="name-title">
                              <h3>{profile.fullName}</h3>
                              <small>{profile.role}</small>
                            </div>
                          </div>
                          <q className="descr">
                            {profile.description}
                          </q>
                          <a target="_blank" rel="noopener noreferrer" href={profile.linkedIn}>LinkedIn Profile</a>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>

            <div className="conclusion">
              <p></p>
            </div>

          </div>
        </section >
      </SectionContainer>
      <Footer />
    </>
  );
}

export default About;
