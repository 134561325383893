import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Navbar, Footer} from '../../../../Controller/index'
import Loader from "../../../../Controller/Loader"

const Project = () => {
  const {projectId} = useParams();
  const [loading, setLoading] = useState(true);
  return (
    <>
    <Navbar />
    {
      loading ? 
      <Loader /> : 
    <section className="project">
       {projectId}
       Get Project
    </section>
    }
    <Footer />
    </>
  );
}

export default Project;
