import React, { useState, useEffect } from "react";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/Footer";
import { useDocumentTitle } from "../../../../Controller/useDocumentTitle";
import { Container, Description } from "./Packages.style";
import { db } from "../../../../Model/setup/firebase";
import { getDoc, doc } from "firebase/firestore";
import { Link } from 'react-router-dom'
import ScrollContainer from 'react-indiana-drag-scroll'
import Loader from "../../../../Controller/Loader"


import "./Packages.css";

const Packages = () => {
  useDocumentTitle("Our Packages");
  const [packages, setPackages] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {

      const docRef = doc(db, "packages", "packages");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setPackages(docSnap.data().packageList)
        setLoading(false)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    fetchData();
  }, []);



  return (
    <div>
      <Navbar />
      {
        loading ? (
          <Loader />
        ) : (
          <Container>
            <h1>Packages & Pricing</h1>
            <div className="description">
              <Description>
                These are some of our packages categorized by industry. With all of our packages, we ensure that we deliver efficiently what is included in high quality. Pick what best fits your needs and let us work together to achieve that.
              </Description>
            </div>
            <section className="package-container">
              {
                packages.map((packageGroup, key) => {
                  if (key === 0 || key === 1) {
                    return (
                      <div key={key} className="packages-row">
                        <div className="package-title">
                          <h3>{packageGroup.title}</h3>
                        </div>
                        <ScrollContainer className="packages-list-container">
                          <div className="packages-list">
                            {packageGroup.packages.map((item, i) => {
                              return (
                                <div key={i} className="package-card-front">
                                  <div className="package-header">
                                    <h3 className="title">{item.name}</h3>
                                  </div>
                                  <div className="package-price">
                                    <h2>{`R${item.price}`}</h2>
                                  </div>
                                  <div className="package-body">
                                    <p>includes</p>
                                    <ScrollContainer className="includes-list-container">
                                      <div className="includes-list">
                                        {
                                          item.includedServices.map((service, j) => {
                                            return (
                                              <h5 key={j}>{service}</h5>
                                            )
                                          })
                                        }
                                      </div>
                                    </ScrollContainer>
                                  </div>
                                  <div className="package-footer">
                                    <Link className="package-link" to="/dashboard">Get Started</Link>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </ScrollContainer>
                      </div>
                    )
                  }
                  return <></>
                })
              }
              {/* <UploadPackages /> */}

              {/* <button onClick={addData}>Add Data</button> */}
            </section>
          </Container>
        )
      }
      <Footer />
    </div>
  );
};
export default Packages;
