import React from "react";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/Footer";
import { SectionContainer } from "./styles/Styles";

function Help() {
  return (
    <>
      <Navbar />
      <SectionContainer>
        <div style={{ display: 'grid', width: '100%', height: '50vh', placeItems: 'center' }}>

          <h1>Help Center Not Yet Available</h1>
        </div>

      </SectionContainer>
      <Footer />
    </>
  );
}

export default Help;
