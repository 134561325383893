import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Loader from "../../../../Controller/Loader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

const Invoices = () => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            {
                loading ?
                    <Loader /> :

                    <div className="client-invoices">
                        <div className="invoices-title">
                            <h1> <Link to="/dashboard"><FontAwesomeIcon style={{ marginRight: '10px', fontSize: '20px' }} icon={faArrowLeft} /></Link>My Invoices</h1>
                        </div>
                        <div class="table-container" role="table" aria-label="Destinations">
                            <div class="flex-table header" role="rowgroup">
                                <div class="flex-row first" role="columnheader">Country</div>
                                <div class="flex-row" role="columnheader">Events</div>
                                <div class="flex-row" role="columnheader">Time</div>
                                <div class="flex-row" role="columnheader">Fees</div>
                            </div>
                            <div class="flex-table row" role="rowgroup">
                                <div class="flex-row first" role="cell"><span class="flag-icon flag-icon-gb"></span> United Kingdom</div>
                                <div class="flex-row" role="cell">Stonehenge, Windsor and Bath with Pub Lunch </div>
                                <div class="flex-row" role="cell">19 Sep, 1p.m.</div>
                                <div class="flex-row" role="cell">US$500</div>
                            </div>
                        </div>
                    </div>
            }
        </>

    )
}

export default Invoices



