import React from 'react'
import {Navbar, Footer} from '../../../Controller/index'
import Image from '../../../assets/store.png';
import {useDocumentTitle} from "../../../Controller/useDocumentTitle"

const Blog = () => {
    useDocumentTitle("Store");
    const container ={
        width: '100vw',
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

    }
    const imageStyle ={
        width: 'auto',
        height: '70%'
    }
    return (
        <div>
            <Navbar />
            <div style={container}>
                <img style={imageStyle} src={Image} alt="blog" />
            </div>
            <Footer />
        </div>
    )
}

export default Blog
