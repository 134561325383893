import React, { useState, useContext, useEffect, useRef } from "react";
import {
  NavContainer,
  FlexContainer,
  Logo,
  ContainerLarge,

} from "../../../styles/styled-components/Navbar.style";
import { NavLink} from "react-router-dom";
import "./Navbar.style.css";

const Navbar = (props) => {

  return (
    <NavContainer>
      <ContainerLarge>
        <FlexContainer className="left-parts">
          <NavLink className="logo-nav" to="/">
            <Logo
              src="https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/logo_1.svg?alt=media&token=008c12e6-aec3-4536-9db7-567ed5f7bb5a"
              alt="logo"
            />
          </NavLink>
        </FlexContainer>

        <FlexContainer className={"right-parts-links"}>
          <h4>Freedom Day Sale</h4>
          
        </FlexContainer> 
      </ContainerLarge>
    </NavContainer>
  );
};



export default Navbar;

