import React, { useState, useContext, useEffect, useRef } from "react";
import {
  NavContainer,
  FlexContainer,
  Logo,
  ContainerLarge,

} from "../../styles/styled-components/Navbar.style";
import { NavLink } from "react-router-dom";
import "./Navbar.style.css";

import { AuthContext } from "../../../AuthProvider";

const Navbar = (props) => {
  const currentUser = useContext(AuthContext)
  const [openNav, setNav] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    console.log(currentUser)
    if (!!currentUser) {
      setIsLoggedIn(true)
    }
  }, [currentUser])

  const navLinks = [
    {
      name: "Home",
      path: "/",
      class: "nav-link"
    },
    {
      name: "Packages",
      path: "/packages",
      class: "nav-link"
    },
    {
      name: "Services",
      path: "/services",
      class: "nav-link"
    },
    {
      name: "How We Work",
      path: "/work-process",
      class: "nav-link"
    },
    {
      name: "Blog",
      path: "/blog",
      class: "nav-link"
    },
    {
      name: "Store",
      path: "/store",
      class: "nav-link"
    },
    {
      name: "Contact",
      path: "/contact",
      class: "nav-link"
    },
    {
      name: "Start A New Project",
      path: "/dashboard",
      class: "new-project"
    },
  ]

  return (
    <NavContainer>
      <ContainerLarge>
        <FlexContainer className="left-parts">
          <NavLink className="logo-nav" to="/">
            <Logo
              src="https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/logo_1.svg?alt=media&token=008c12e6-aec3-4536-9db7-567ed5f7bb5a"
              alt="logo"
            />
          </NavLink>
        </FlexContainer>

        <FlexContainer className={openNav ? "right-parts-links" : "right-parts-links open"}>
          {
            navLinks.map((item, i) => {
              return <NavLink key={i} className={item.class} to={item.path}>
                {
                  (item.name === "Start A New Project") ?
                    ((isLoggedIn) ? "Dashboard" : item.name) :
                    item.name
                }
              </NavLink>
            })
          }

        </FlexContainer>
        <div className="menu-bar-container" onClick={() => setNav(!openNav)}>
          <div className={openNav ? "menu-bar" : "cross-bar"} onClick={() => setNav(!openNav)}></div>
        </div>
      </ContainerLarge>
    </NavContainer>
  );
};



export default Navbar;

