import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import {useDocumentTitle} from "../../../Controller/useDocumentTitle"

import './useful-pages/styles/WorkProcess.css'


const WorkProcess = () => {
  useDocumentTitle("How We Work");
  return (
    <div>
      <Navbar />
        <section className="work-process">
          <h1>How We Work</h1>
          <p>We have established a way that we are sure works best in delivering our services in a way that is efficient and fast.</p>
          <div className="design-process">
            <h1>Graphic Design Process</h1>
            <img src="https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/resources%2Fdesign-process-final.png?alt=media&token=fcdd0135-9e67-4b4a-a7ab-860988b43cfa" alt="background" />
          </div>  
          <div className="development-process">
            <h1>Development Process</h1>
            <img src="https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/resources%2Fdevelopment-process.png?alt=media&token=a2ffc8de-866d-4de7-82b0-ef91e3f94aa9" alt="background" />
          
          </div>
        </section>
      <Footer />
    </div>
  );
}

export default WorkProcess;
