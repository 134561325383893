import React, {useState, useEffect} from 'react'
import {db} from "../../Model/setup/firebase"
import { doc, onSnapshot, updateDoc } from "firebase/firestore";

const VotingSystem = () => {
  const [votes, setVotes] = useState([])
  const [newVotes, setNewVotes] = useState([])
  const [currentVote, setCurrentVote] = useState(null)
    useEffect(() => {
    onSnapshot(doc(db, "voting-system", "cars"), (document) => {
        console.log("Current data: ", document.data());
        let tempArray = []
        for(var key in document.data()) {
            tempArray.push({...document.data()[key], name: key});
        }
        setVotes(tempArray)
    });
    }, [])
    function placeVote(index) {
        setCurrentVote(index)
        let votesArray = [...votes]
        votesArray[index] = {...votesArray[index], votes: votesArray[index].votes + 1}
        setNewVotes(votesArray)
        setVotes(votesArray)
    }
    async function submitVote() {
        const washingtonRef = doc(db, "voting-system", "cars");
        // Set the "capital" field of the city 'DC'
        let votesArray = [...votes]
        let update = {
            votes: votesArray[currentVote].votes,
            id: votesArray[currentVote].id
        }
        await updateDoc(washingtonRef, {[votesArray[currentVote].name]: update});
    }
  return (
    <>
        <div>VotingSystem</div>
        <p>{currentVote}</p>
        {
            votes.map((item, i) => {
                return (
                    <div key={i}>
                        <h1>{item.name}</h1>
                        <h1>{`${Object.keys(item)[1]}: ${item[Object.keys(item)[1]]}`}</h1>
                        <h1>{`${Object.keys(item)[0]}: ${item[Object.keys(item)[0]]}`}</h1>
                        <button onClick={() => placeVote(i)}>Vote</button>
                        <br />
                    </div>
                )
            })
        }
        <button onClick={submitVote} >Submit Vote</button>
    </>

  )
}

export default VotingSystem