import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../../AuthProvider';
import { Link, useNavigate } from 'react-router-dom'
import { db, storage } from '../../../../Model/setup/firebase'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../Controller/Loader'
import { Navbar, Footer } from '../../../../Controller/'
import UserDetails from './newProject/UserDetails';
import Success from './newProject/Success';
import Confirmation from './newProject/Confirmation';
import PersonalDetails from './newProject/PersonalDetails';
import './newProject/NewProject.css'
import { Services, graphicServices, webServices, mediaServices, assistanceServices } from "./newProject/Data"



const StartNewProject = () => {
    const currentUser = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const progressList = [
        "Service Type", "SubService", "Additional Information", "Book A Consultation", "Confirmation", "Save Project"
    ]
    const [pageState, setPageState] = useState({
        step: 1,
        serviceType: '',
        serviceSubType: '',
        otherSubType: '',
        additionalInfo: '',
        urgency: '',
        country: '',
        levelOfEducation: '',
    })
    function headerInfo() {
        return (
            <div className="header">
                <div className="step-progress-list">

                </div>
            </div>
        )
    }
    const buttonVisibility = (step) => {
        if (step === 1) {
            return (
                <button onClick={nextPage} className="card-nav-btn">Next</button>
            )
        } else if (step > 1 && step < 5) {
            return (
                <>
                    <button onClick={prevPage}  className="card-nav-btn">Previous</button>
                    <button onClick={nextPage} className="card-nav-btn">Next</button>
                </>
            )
        }
        else if (step === 5) {
            return (
                <button onClick={prevPage}  className="card-nav-btn">Previous</button>
            )
        }
    }
    function nextPage() {
        console.log(pageState.step)
        console.log("service "+pageState.serviceType)
        if (pageState.serviceType.length === 0 ) {
            toast("Select An Option To Continue")
            return;
        }
        console.log("clicked")
        setPageState({...pageState, step: pageState.step + 1});
    }
    function prevPage() {
        console.log("clicked")
        setPageState({...pageState, step: pageState.step - 1});
    }
    switch (pageState.step) {
        case 1:
            return (
                <>
                    <Navbar />
                    <StartProject navFunction={buttonVisibility} handlePageState={setPageState} pageState={pageState} />
                    <Footer />
                </>
            )
        case 2:
            return (
                <>
                    <Navbar />
                    <ChooseSubService navFunction={buttonVisibility} handlePageState={setPageState} pageState={pageState}/>
                    <Footer />
                </>
            )
        case 3:
            return (
                <>
                    <Navbar />
                    <Confirmation />
                    <Footer />
                </>
            )
        case 4:
            return (
                <>

                    <Navbar />
                    <Success />
                    <Footer />
                </>
                // never forget the default case, otherwise VS code would be mad!
            )
        default:
            return (
                <>
                    <Navbar />
                    {
                        loading ?
                            <Loader /> :
                            <div className="start-project-container">
                                <h1>Start A New Project</h1>
                            </div>
                    }
                    <Footer />
                </>
            )
    }
}

export default StartNewProject

const StartProject = (props) => {
    const [selected, setSelected] = useState("")
    const [isSelected, setIsSelected] = useState([])
    function selectedService(key) {
        console.log(key)
        console.log(Services[key])
        let temp = [false, false, false, false]
        for (let i = 0; i < temp.length; i++) {
            temp[i] = false;
        }
        temp[key] = true
        setSelected(Services[key])
        setIsSelected(temp)
        props.handlePageState({...props.pageState, serviceType: Services[key] })

    }

    return (
        <div className="start-project-container">
            {/* <h1>Start A New Project</h1>
            <p className="description">A quick start guide to a project</p> */}
            <div className="new-project-card">
                <div className="project-card-header">
                    <h3>Choose A Service Category</h3>
                </div>
                <div className="new-project-card-body">
                    <div className="project-service-list">
                        {   
                            Services.map((service, key) => {
                                return (
                                    <div key={key} onClick={() => selectedService(key)} className={Services[key] === props.pageState.serviceType ? "project-service-card selected" : "project-service-card"}>
                                        <h5>{service}</h5>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="project-card-footer">

                    {
                        props.navFunction(props.pageState.step)
                    }
                </div>
            </div>
            <ToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        </div>
    )
}

const ChooseSubService = (props) => {
    const [selected, setSelected] = useState(null)
    const [isSelected, setIsSelected] = useState([])
    const [showSpecifyField, setShowSpecifyField] = useState(false)
    const [activeList, setActiveList] = useState([])
    


    const subServices = [graphicServices, webServices, mediaServices, assistanceServices ]
    
    useEffect(() => {
        setActiveList(subServices[Services.indexOf(props.pageState.serviceType)])
    }, [])

    function selectedService(key) {
        setIsSelected(activeList[key])
        props.handlePageState({...props.pageState, serviceSubType: activeList[key] })
        setShowSpecifyField(false)
    }
    function selectedSpecify() {
        setShowSpecifyField(!showSpecifyField)
        props.handlePageState({...props.pageState, serviceSubType: "" })
        // props.handlePageState({...props.pageState, serviceSubType: "" })

    }

    return (
        <div className="start-project-container">
            <div className="new-project-card">
                <div className="project-card-header">
                    <h3>Choose A Service</h3>
                </div>
                <div className="new-project-card-body">
                    <div className="project-sub-service-list">
                        {   
                            
                            activeList.map((service, key) => {
                                return (
                                    <div key={key} onClick={() => selectedService(key)} className={activeList[key] === props.pageState.serviceSubType ? "project-service-card selected" : "project-service-card"}>
                                        <h5>{service}</h5>
                                    </div>
                                )
                            })
                        }  
                        <div onClick={selectedSpecify} className={ showSpecifyField ? "project-service-card selected" : "project-service-card"}>
                            <h5>{`Other Service`}</h5>
                        </div>
                    </div>
                    {props.pageState.serviceSubType}
               
                    {
                        showSpecifyField && 
                        (
                            <InputForm formHandle={props.handlePageState} pageState={props.pageState} />
                        )
                    }
                </div>
                <div className="project-card-footer">

                    {
                        props.navFunction(props.pageState.step)
                    }
                </div>
            </div>
        </div>
    )
}

const InputForm = (props) => {
    return (
        <div className="input-form-container">
            <label htmlFor="textInput">Specify Service</label>
            <input type="text" id="textInput" placeholder="Specify service" onChange={(e) => props.formHandle({...props.pageState, serviceSubType: e.target.value})} value={props.pageState.serviceSubType}/>
        </div>
    )
}



const AdditionalInformation = (props) => {
    return (
        <div className="additional-info-container">
            
        </div>
    )
}
