import React from 'react';

const Payment = () => {
  return (
    <div>
      
    </div>
  );
}

export default Payment;
