import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faPenNib, faCode, faPhotoVideo } from "@fortawesome/free-solid-svg-icons";

const Solutions = () => {
    const Solutions = [
        {
            title: "Graphic Design",
            icon: faPenNib,
            description: "Quality and professional graphics design services to help your brand or business effectively communicate the message to your target audience, from logo design, banners to websites."
        },
        {
            title: "Software Development",
            icon: faCode,
            description: "Software for businesses are really important because they make business processes much more effecient and effective, and business reach more markets. These ranges from online systems to website and applications."
        },
        {
            title: "Media Solutions",
            icon: faPhotoVideo,
            description: "Our lives now revolve around online content and content creation. Since this is a major change in the business industry as well we assist businesses in creating valuable content to engage with their target audiences, potential customers."
        },
    ]
    return (
        <div id="solutions">
            <div className="solutions-title">
                <h1>Digital Solutions We Offer</h1>
            </div>
                <div className="solutions-container">
                    {
                        Solutions.map((item, i) => {
                            return (
                                <div key={i} className="solution-card">
                                    <div className="solution-icon-container">
                                        <FontAwesomeIcon className="solution-icon" icon={item.icon} />
                                    </div>
                                    <h1 className="solution-title">
                                        {item.title}
                                    </h1>

                                    <p className="solution-body">{item.description}</p>

                                </div>
                            )
                        })
                    }

                </div>
          </div>
    );
}

export default Solutions;
