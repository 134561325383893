import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from './navbar/Navbar'
import CountDown from './CountDown'
import { db } from '../../../Model/setup/firebase'
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useDocumentTitle } from "../../../Controller/useDocumentTitle"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../Controller/Loader'

import './Freedom.style.css'

const OverViewPage = () => {
    const [loading, setLoading] = useState(false)
    const [toSend, setToSend] = useState({
        name: '',
        email: '',
        createdAt: serverTimestamp(),
        // time: Timestamp.fromDate(new Date().toLocaleTimeString()),
        status: ''
    })
    async function submitForm(e) {
        e.preventDefault();

        setLoading(true)
        await addDoc(collection(db, "freedom-promo-reminder-list"),
            {
                name: toSend.name,
                email: toSend.email,
                createdAt: serverTimestamp(),
            }
        ).then((snap) => {
            console.log("message sent")
            toast("Added To Reminder List");
            console.log(snap)
            setToSend({
                name: '',
                email: '',
            })
            setLoading(false)
        });

    }
    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };
    const ServiceList = [
        {
            name: "Poster Design",
            description: "Clear, concise and morden style poster that is eye-catching and attractive.",
            currentPrice: 143.50,
            previousPrice: 410.00,
            icon: "https://firebasestorage.googleapis.com/v0/b/nkululeko-store.appspot.com/o/freedom-day%2Fservices%2Fposter%2FPOSTER.svg?alt=media&token=5ac94265-18d0-4c5e-be80-c0e300be33f9",
        },
        {
            name: "Logo Design",
            description: "Simple and relevant logo design but also memorable and versatile.",
            currentPrice: 236.00,
            previousPrice: 590.00,
            icon: "https://firebasestorage.googleapis.com/v0/b/nkululeko-store.appspot.com/o/freedom-day%2Fservices%2Flogo%2FLOGO.svg?alt=media&token=7fa70591-15e6-4db5-aac7-f180a42e2400",
        },
        {
            name: "Mobile App UI",
            description: "Concepts and Ideas visually illustrated.",
            currentPrice: 800.00,
            previousPrice: 2000.00,
            icon: "https://firebasestorage.googleapis.com/v0/b/nkululeko-store.appspot.com/o/freedom-day%2Fservices%2Fmobile-ui%2FMOBILE-UI.svg?alt=media&token=6ebe55f3-3baf-4808-ad49-196ea40f1104",
        },
        {
            name: "Mockup Design",
            description: "Clean mock clothing representation.",
            currentPrice: 45.00,
            previousPrice: 150.00,
            icon: "https://firebasestorage.googleapis.com/v0/b/nkululeko-store.appspot.com/o/freedom-day%2Fservices%2Fmockup-design%2FMOCKUP.svg?alt=media&token=04f9bb9e-237d-4c96-8a4c-0661125db70c",
        },
        {
            name: "One Page Website",
            description: "Modern design and mobile-friendly website.",
            currentPrice: 1140.00,
            previousPrice: 1900.00,
            icon: "https://firebasestorage.googleapis.com/v0/b/nkululeko-store.appspot.com/o/freedom-day%2Fservices%2Fweb-dev%2FWEB-DEV.svg?alt=media&token=d7a39cfc-7944-455b-a748-e18a0a650f7e",
        },
        {
            name: "Web UI Design",
            description: "Modern User Interface design.",
            currentPrice: 495.00,
            previousPrice: 900.00,
            icon: "https://firebasestorage.googleapis.com/v0/b/nkululeko-store.appspot.com/o/freedom-day%2Fservices%2Fweb-ui%2FWEB-UI.svg?alt=media&token=20e8588e-8083-4190-8cc3-700ac5b15dd1",
        },
        {
            name: "Image Editing",
            description: "Profesional and clean image editing.",
            currentPrice: 45.00,
            previousPrice: 150.00,
            icon: "https://firebasestorage.googleapis.com/v0/b/nkululeko-store.appspot.com/o/freedom-day%2Fservices%2Fimage-edits%2FIMAGE-EDITS.svg?alt=media&token=b640ee7c-67b8-488f-8e9a-e9d417cfd744",
        },
        {
            name: "Web Application",
            description: "Custom design and systems integrated to the app.",
            currentPrice: 10369.99,
            previousPrice: 12199.99,
            icon: "https://firebasestorage.googleapis.com/v0/b/nkululeko-store.appspot.com/o/freedom-day%2Fservices%2Fweb-app%2FWEB-APP.svg?alt=media&token=2a67fb12-4c79-4764-9575-1fc036cee11f",
        },
    ]
    return (
        <>
            {/* <CountDown /> */}
            <Navbar />
            <section className="landing-page-container">
                <div className="sale-hero">
                    <img className="sale-icon" src="https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/promotion-resources%2FSaleTag.svg?alt=media&token=4c2f405c-88fd-4d69-9206-a5b1636dd606" alt="'sale-tag" />
                    <p>NKULULEKO DOT IO, presents a One Day Sale on the 27th of April, (Freedom Day), Make sure you don't miss this epic sale.</p>
                </div>
                <form action="" onSubmit={submitForm}>
                    {
                        loading ? (
                            <Loader />
                        ) : (


                            <div className="email-reminder wrapper">
                                <h4>Get A Reminder When The Sale Starts</h4>
                                <div className="form-group">
                                    <label className="legend-top">
                                        <input type="text" name="name" placeholder="Name" onChange={(e) => handleChange(e)} />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label className="legend-top">
                                        <input type="text" name="email" placeholder="Email" onChange={(e) => handleChange(e)} />
                                    </label>
                                </div>
                                <input type="submit" className="submit-button" />

                            </div>
                        )
                    }
                </form>
                <div className="sale-products-container">
                    <div className="sale-products">
                        {
                            ServiceList.map((service, key) => {
                                return (
                                    <Service key={key} name={service.name}
                                        discount={service.discountTag}
                                        description={service.description}
                                        currentPrice={service.currentPrice}
                                        previousPrice={service.previousPrice}
                                        icon={service.icon}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                <ToastContainer
                    position="bottom-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </section>
        </>
    )
}

export default OverViewPage


const Service = (props) => {
    return (
        <div className="service-wrapper">
            <div className="left">
                <div className="icon">
                    <img src={props.icon} alt={props.name} />
                </div>
            </div>
            <div className="right">
                <h2>{props.name}</h2>
                <p>{props.description}</p>
                <div className="price">
                    <h3>{`R${props.previousPrice}`}</h3>
                    <h1>{`R${props.currentPrice}`}</h1>
                </div>
            </div>
        </div>
    )
}