import React, {useState, useEffect} from 'react'
import Navbar from './navbar/Navbar'
import './Freedom.style.css'
import CountDown from './CountDown'
import {Link} from 'react-router-dom'

const LandingPage = () => {
    
    return (
        <>
            <CountDown />
            <Navbar />
            <section className="landing-page-container">
                <div className="sale-hero">
                    <img className="sale-icon" src="https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/promotion-resources%2FSaleTag.svg?alt=media&token=4c2f405c-88fd-4d69-9206-a5b1636dd606" alt="'sale-tag" />
                </div>
                <div className="buttons">
                    <Link className="btn services" to="/freedom-day-sale/services">Services</Link>
                    <Link className="btn merch" to="/freedom-day-sale/merchandise">Our Business Merchandise</Link>
                </div>
            </section>
        </>
    )
}
export default LandingPage