export const Services = [
    "Graphic Design",
    "Web Development And Programming Solutions",
    "Media Solutions",
    "Tech Virtual Assistance"
]

export const graphicServices = [
    "Logo Design",
    "Business Card Design",
    "Stationery Design",
    "Label Design",
    "Letterhead Design",
    "Signage Design",
    "Billboard Design",
    "Trade Show Booth Design",
    "Vinyl Banners",
    "Invoices",
    "Reports",
    "Presentations",
    "Brochures",
    "Posters",
    "Leaflets",
    "Flyers",
    "Greeting Cards",
    "Invitations",
    "Teaser Cards",
    "Resume Design",
    "Word Template Design",
    "Infographic Design",
    "Creating brand style guides"
]
export const webServices = [
    "Web Design",
    "Wordpress Design",
    "Landing Page Design",
    "App UI Design",
    "Wix Design",
    "Shopyfy Design",
    "Email Marketing Design",
    "Banner Ad Design",
    "Website Development",
    "Copywriting",
    "Web Application Development",
    "Web Services",
    "Scripting",
    "Database Design",
    "Online Systems",
    "Online Services Integrations",
    "Website security and backups",
    "Website SEO",
    "Creating online quizzes",
    "Managing eCommerce sites",
    "Managing customer databases",
    "Updating websites and plugins",
    "Web design/maintenance",
    "Setting up secure websites (https)",
]
export const mediaServices = [
    "Account Creation",
    "Social Media Profile Branding",
    "Content Creation",
    "Social Media Management",
    "Content Publishing",
    "Strategy planning",
    "Social Media research and analysis",
    "Social Media consultation",
    "Ads setup and management",
    "Image Editing",
    "Video Editing",
    "Script planning and writing",
    "Responding to social media messages",
    "Setting up and managing Social Media groups",
    "Responding to social media messages",
    "Photography for websites and promotional materials",
    "Analytics reporting (Google Analytics, email, social media, etc)"

]
export const assistanceServices = [
    "Ghostwriting",
    "Creating product descriptions",
    "Collecting/editing testimonials",
    "Letter writing",
    "Podcast scripting",
    "Formatting blog posts",
    "Setting up goals in Google Analytics",
    "Setting up or migrating email lists to a new email service provider",
    "Setting up newsletter opt-in forms and sequences",
    "Database creation and management",
    "Shopping cart installation/maintenance",
    "Podcast submission",
    "Creating surveys and online forms",
    "Troubleshooting, IT support",
    "Creating and managing affiliate programs",
    "Streamlining and automating systems",
    "Creating digital magazines",
    "File conversion",
    "Setting up spreadsheets",
    "Tracking and fixing broken links",
    "Ecourse creation",
    "Setting up membership sites",
    "Website coding",
    "Ad management",
    "Setting up digital product delivery",
    "Formatting ebooks",
    "Software research",
    "Solution research",
]
